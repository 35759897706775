<template>
    <div>
        <Setup :step="Step" />
    </div>
</template>

<script>
import Setup from "@/components/Widget/Setup.vue"
import { SYSTEM_STATUS_REQUEST } from "@/store/actions/system"

export default {
    name: "Dashboard",
    components: {
        Setup
    },
    data() {
        return {
            Step: 0
        }
    },
    created() {
        if (!this.$store.getters.IS_SYSTEM_STATUS_LOADED) {
            this.$store.dispatch(SYSTEM_STATUS_REQUEST)
                .then(() => {
                    if (this.$store.getters.IS_DOOR_PROVIDER_BOUNDED) this.Step = 1
                    if (
                        this.$store.getters.IS_DOOR_PROVIDER_BOUNDED
                        && this.$store.getters.IS_AT_LEAST_ONE_DOOR_AVAILABLE
                    ) this.Step = 2
                    if (
                        this.$store.getters.IS_DOOR_PROVIDER_BOUNDED
                        && this.$store.getters.IS_AT_LEAST_ONE_DOOR_AVAILABLE
                        && this.$store.getters.IS_AT_LEAST_ONE_KEY_CREATED
                    ) this.Step = 3
                    if (
                        this.$store.getters.IS_DOOR_PROVIDER_BOUNDED
                        && this.$store.getters.IS_AT_LEAST_ONE_DOOR_AVAILABLE
                        && this.$store.getters.IS_AT_LEAST_ONE_KEY_CREATED
                        && this.$store.getters.IS_CONTRACT_CONCLUDED
                    ) this.Step = 4
                })
        }
    }
}
</script>
