<template>
    <div>
        <div class="setup card">
            <div class="card-content">
                <template v-if="step < 4">
                    <p class="title">{{ $t("Interface.Setup.NotOver.Title") }}</p>
                    <p class="subtitle">{{ $t("Interface.Setup.NotOver.Subtitle") }}</p>
                </template>
                <template v-else>
                    <p class="title">{{ $t("Interface.Setup.Complete.Title") }}</p>
                    <p class="subtitle">{{ $t("Interface.Setup.Complete.Subtitle") }}</p>
                </template>
                <b-progress
                    :value="PercentComplete"
                    size="is-large"
                    show-value
                    format="percent"
                />
                <div class="setup-steps">
                    <ul>
                        <li
                            v-for="Step in Steps"
                            :key="Step.Number"
                        >
                            <div class="setup-step field" :class="{ 'is-over': Step.Number <= step }">
                                <b-checkbox disabled :value="Step.Number <= step">
                                    {{ Step.Title }}
                                </b-checkbox>
                            </div>
                        </li>
                    </ul>
                </div>
                <br>
                <b-button v-if="step === 0" type="is-theme" @click.prevent="$router.push({ name: 'DoorProvider-Add' })">
                    {{ $t("Interface.Setup.Step1.ButtonConnectProvider") }}
                </b-button>
                <b-button v-if="step === 1" type="is-theme" @click.prevent="$router.push({ name: 'Door-List' })">
                    {{ $t("Interface.Door.List.PullButton") }}
                </b-button>
                <b-button v-if="step === 2" type="is-theme" @click.prevent="$router.push({ name: 'Key-Create' })">
                    {{ $t("Interface.Key.List.AddButton") }}
                </b-button>
                <b-button v-if="step === 3" type="is-theme" @click.prevent="$router.push({ name: 'Contract' })">
                    {{ $t("Interface.Contract.Details.CreateButton") }}
                </b-button>
                <template v-if="step === 0">
                    <br>
                    <br>
                </template>
            </div>
        </div>
        <div v-if="step === 0" id="amocrm" />
    </div>
</template>

<script>
export default {
    name: "Setup",
    props: {
        step: {
            default: 0,
            type: Number
        }
    },
    computed: {
        PercentComplete() {
            return this.step * 25
        },
        Steps() {
            return [
                {
                    Number: 1,
                    Title: this.$t("Interface.Setup.Step1.Title")
                },
                {
                    Number: 2,
                    Title: this.$t("Interface.Setup.Step2.Title")
                },
                {
                    Number: 3,
                    Title: this.$t("Interface.Setup.Step3.Title")
                },
                {
                    Number: 4,
                    Title: this.$t("Interface.Setup.Step4.Title")
                }
            ]
        }
    },
    mounted() {
        if (this.step === 0) this.loadAMOScript()
    },
    methods: {
        loadAMOScript() {
            window.amo_forms_params = {
                id: 680617,
                hash: "d511e80b54cd067d8187d3736322e8a4",
                locale: "ru"
            }
            const script = document.createElement("script")
            script.src = "https://forms.amocrm.ru/forms/assets/js/amoforms.js"
            script.type = "text/javascript"
            script.id = "amoforms_script"
            script.async = "async"
            script.charset = "utf-8"
            document.getElementById("amocrm").appendChild(script)
        }
    }
}
</script>
